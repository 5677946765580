import { isMobile, debounce } from '../helpers/helpers';

class Footer {
	constructor() {
		this.eventFooter();
		this.handleFooterResize();
	}

	footerCollapsable() {
		const menu = this.nextElementSibling;
		menu.classList.toggle('footer-collapsible-visible');
		const title = this.firstElementChild;
		const icon = title.firstElementChild;
		icon.classList.toggle('nav-icon-plus');
		icon.classList.toggle('nav-icon-minus');
		icon.classList.toggle('nav-icon-plus--right');
		icon.classList.toggle('nav-icon-minus--right');
		const expanded = this.getAttribute('aria-expanded') === 'true' || false;
		this.setAttribute('aria-expanded', !expanded);
		menu.setAttribute('aria-hidden', expanded);
	}

	eventFooter() {
		const fBtns = document.querySelectorAll('.footer-col-btn');
		const arrayfBtns = [...fBtns];

		if (isMobile()) {
			arrayfBtns.forEach((fBtn) => {
				fBtn.tabIndex = 0;
				fBtn.setAttribute('aria-expanded', false);
				fBtn.removeEventListener('click', this.footerCollapsable);
				fBtn.addEventListener('click', this.footerCollapsable);
			});
		} else {
			arrayfBtns.forEach((fBtn) => {
				fBtn.tabIndex = -1;
				fBtn.setAttribute('role', 'presentation');
				fBtn.setAttribute('aria-hidden', true);
				fBtn.removeAttribute('aria-expanded');
				fBtn.removeEventListener('click', this.footerCollapsable);
				fBtn.nextElementSibling.setAttribute('aria-hidden', false);
			});
		}
	}

	handleFooterResize() {
		window.addEventListener(
			'resize',
			debounce(() => {
				this.eventFooter();
			}, 100)
		);
	}
}

export default Footer;
