// Handling the modal box
import { lockScrolling, unlockScrolling } from '../helpers/helpers';

class Modal {
	constructor() {
		this.modal = document.querySelector('.nav-modal');
		this.modalBtn = document.querySelector('#nav-modalBtn');
		this.closeBtn = document.querySelector('#nav-modalClose');

		this.handleModal();
	}

	handleModal() {
		if (this.modal) {
			if (this.modalBtn) {
				this.modalBtn.addEventListener('click', () => {
					this.modal.style.display = 'block';
					this.closeBtn.focus();
					lockScrolling();
					this.keyboardTrap();
				});
			}

			if (this.closeBtn) {
				this.closeBtn.addEventListener('click', () => {
					this.modal.style.display = 'none';
					unlockScrolling();
				});
			}

			window.onkeyup = (e) => {
				if (e.keyCode === 27) {
					this.modal.style.display = 'none';
					unlockScrolling();
				}
			};

			window.onclick = (e) => {
				if (e.target === this.modal) {
					this.modal.style.display = 'none';
					unlockScrolling();
				}
			};
		}
	}

	keyboardTrap() {
		const focusable = this.modal.querySelectorAll('button , a');
		const lastLink = focusable[focusable.length - 1];

		this.closeBtn.addEventListener('keydown', (e) => {
			if (e.keyCode === 9 && e.shiftKey) {
				e.preventDefault();
				lastLink.focus();
			}
		});

		lastLink.addEventListener('keydown', (e) => {
			if (e.keyCode === 9 && !e.shiftKey) {
				e.preventDefault();
				this.closeBtn.focus();
			}
		});
	}
}

export default Modal;
