import {
	isMobile,
	debounce,
	lockScrolling,
	unlockScrolling,
} from '../helpers/helpers';

class MobileMenu {
	constructor() {
		this.siteHeader = document.querySelector('.nav-container');
		this.menuIcon = document.querySelector('.nav-mobile-header__menu-icon');
		this.navMobileCloseBtn = document.querySelector('.nav-mobile__close-btn');
		this.navMobileIconX = document.querySelector('.nav-mobile__icon-x');
		this.navMobile = document.querySelector('.nav-mobile-header__container');

		this.navMobileCta = document.querySelector('.nav-mobile__cta');
		if (this.navMobileCta) {
			this.megaMenuBtnMobile = this.navMobileCta.querySelector(
				'.nav-mega-menu__btn--mobile'
			);
		}
		this.navAnchors = document.querySelectorAll(
			'.nav-primary-menu a[href*="#"], .nav-microsite-menu a[href*="#"] '
		);
		this.handleMobileMenu();
		this.handleMobileResize();

		this.handleOutsideClick();
	}

	handleMobileMenu() {
		if (this.menuIcon !== null) {
			this.menuIcon.addEventListener('click', () => {
				this.openMobileMenu();
			});
		}

		if (this.navMobileIconX !== null) {
			this.navMobileIconX.addEventListener('click', () => {
				this.closeMobileMenu();
			});
		}

		if (this.megaMenuBtnMobile !== null) {
			this.megaMenuBtnMobile.addEventListener('click', () => {
				if (this.siteHeader.classList.contains('nav-container-open')) {
					this.closeMobileMenu();
				}
			});
		}

		if (this.navAnchors) {
			const body = document.querySelector('body');
			const bodyStyles = window.getComputedStyle(body);
			const bodyPaddingTop = parseInt(
				bodyStyles.getPropertyValue('padding-top'),
				10
			);
			const arrayAnchors = [...this.navAnchors];
			arrayAnchors.forEach((anchor) => {
				anchor.addEventListener('click', (e) => {
					if (this.compareURL(anchor.href)) {
						e.preventDefault();
						if (this.siteHeader.classList.contains('nav-container-open')) {
							this.closeMobileMenu();
						}
						const target = document.querySelector(`${anchor.hash}`);
						if (target) {
							const distanceFromTopOfViewport = target.getBoundingClientRect()
								.top;
							const alreadyScrolled = this.getBodyScrollTop();
							if (distanceFromTopOfViewport) {
								const totalToScroll =
									distanceFromTopOfViewport - bodyPaddingTop + alreadyScrolled;
								window.scrollTo(0, totalToScroll);
							}
						}
					}
				});
			});
		}
	}

	getBodyScrollTop() {
		const el = document.scrollingElement || document.documentElement;
		return el.scrollTop;
	}

	openMobileMenu() {
		this.menuIcon.setAttribute('aria-expanded', true);
		this.siteHeader.setAttribute('aria-hidden', false);
		this.siteHeader.classList.add('nav-container-open');
		this.navMobileCloseBtn.classList.add('nav-mobile__close-btn--visible');
		this.navMobileCloseBtn.classList.add('nav-animate__open--left');
		this.siteHeader.classList.add('nav-animate__open--left');
		lockScrolling();
	}

	closeMobileMenu() {
		this.menuIcon.setAttribute('aria-expanded', false);
		this.siteHeader.setAttribute('aria-hidden', true);
		this.navMobileCloseBtn.classList.remove('nav-mobile__close-btn--visible');
		this.navMobileCloseBtn.classList.remove('nav-animate__open--left');
		this.siteHeader.classList.remove('nav-animate__open--left');
		this.siteHeader.classList.add('nav-animate__close--left');
		this.siteHeader.addEventListener('animationend', (e) => {
			if (e.animationName === 'nav-anim__close-left') {
				this.siteHeader.classList.remove('nav-container-open');
				this.siteHeader.classList.remove('nav-animate__close--left');
				this.navMobileCloseBtn.classList.remove(
					'nav-mobile__close-btn--visible'
				);
			}
		});
		unlockScrolling();
	}

	eventResize() {
		if (isMobile()) {
			this.siteHeader.setAttribute('aria-hidden', true);
		} else {
			this.siteHeader.classList.remove('nav-container-open');
			this.siteHeader.setAttribute('aria-hidden', false);
			unlockScrolling();
			this.navMobileCloseBtn.classList.remove('nav-mobile__close-btn--visible');
		}
	}

	handleMobileResize() {
		window.addEventListener(
			'resize',
			debounce(() => {
				this.eventResize();
			}, 100)
		);
	}

	handleOutsideClick() {
		document.addEventListener('click', (e) => {
			if (
				isMobile() &&
				this.siteHeader.classList.contains('nav-container-open') &&
				!this.navMobile.contains(e.target) &&
				!this.siteHeader.contains(e.target)
			) {
				this.closeMobileMenu();
			}
		});
	}

	compareURL(link) {
		const localPage = document.location.href.match(/(^[^#]*)/)[0];
		const linkTarget = link.match(/(^[^#]*)/)[0];
		return localPage === linkTarget;
	}
}

export default MobileMenu;
