class SkipNavigation {
	constructor() {
		this.handleSkipNavigation();
	}

	handleSkipNavigation() {
		const skipNav = document.querySelector('.skipNavigation');
		if (skipNav) {
			const anchors = document.querySelectorAll('[data-skip-navigation]');
			const anchorsToArray = Array.prototype.slice.call(anchors);

			if (anchors !== null) {
				skipNav.style.display = 'block';
				// This is a empty tag to prevent the Cookie Banner to focus on the skip navigation btn when you close it
				// July 13th 2022 - Removing it because it doesn't seems necessary anymore
				// skipNav.innerHTML += `<a id="oneTrustTarget" style="visibility:hidden"></a>`;

				anchorsToArray.forEach((anchor) => {
					if (document.querySelector('html').classList.contains('IE')) {
						anchor.setAttribute('tabindex', '0');
					}
					skipNav.innerHTML += `<a href="#${
						anchor.id
					}" class="skipNavigation__link">${anchor.getAttribute(
						'data-skip-navigation-label'
					)}</a>`;
				});
			}
		}
	}
}

export default SkipNavigation;
