class LegacySupport {
	constructor() {
		this.view = document.querySelector('#site');
		this.mainHeader = document.querySelector('header.main-header');
		this.nav = document.querySelectorAll('header.main-header nav');
		this.dataView = document.querySelector('[data-view]');

		this.managePaddingTop();
		this.addClassToHeader();
		window.emergencyBannerOnClose = this.managePaddingTop.bind(this);
	}

	managePaddingTop() {
		// this is to handle the difference betweeen the legacy nav and universal nav
		// Legacy Nav was 48px of height / Universal Nav is 60 px of height
		// Once the legacy nav is not on the website anymmore and that [data-view] doesnt have 48px padding top anymore
		// then you can remove that extraPadding
		// Similar functions are in
		// C:\Git\CDS1024\Components\src\Foundation\Theming\code\src\Components\universal-nav\assets\scripts\modules\LegacySupport.js
		// C:\Git\CDS1024\Components\src\Foundation\Theming\code\src\Components\banner\assets\scripts\modules\ModuleBanner.js
		// C:\Git\CDS1024\CdsDotCom\src\Foundation\Frontend\code\front-end\component\core\src\js\modules\ModulePromoBanner.js
		if (this.mainHeader && this.nav && this.view) {
			let extraPadding = 0;
			if (
				this.dataView &&
				getComputedStyle(this.dataView).paddingTop === '48px'
			) {
				extraPadding += 12;
			}
			const isLegacyNav = this.nav.length > 1;
			const newPaddingTop =
				this.mainHeader.clientHeight -
				(this.nav[0].clientHeight +
					(this.nav[1] ? this.nav[1].clientHeight : 0)) +
				(isLegacyNav ? 0 : extraPadding);
			this.view.style.paddingTop = `${newPaddingTop}px`;
		}
	}

	addClassToHeader() {
		this.mainHeader.classList.add('universal-nav');
	}
}

export default LegacySupport;
