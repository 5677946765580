import { isMobile, debounce, throttle } from '../helpers/helpers';

class ScrollTop {
	constructor() {
		const btn = document.querySelector('.nav-back-to-top__btn');
		this.body = document.querySelector('body');
		this.isFoldable = document.querySelector('.nav--foldable');

		btn.addEventListener('click', () => this.toTheTop());

		this.handleScrollTop();

		this.previousScrollPositon = window.scrollY || window.pageYOffset;
	}

	handleScrollTop() {
		// window.addEventListener('scroll', () => this.scrollFunction());
		window.addEventListener(
			'scroll',
			throttle(() => {
				this.scrollFunction();
			}, 100)
		);
	}

	scrollFunction() {
		const backToTopBtn = document.querySelector('.nav-back-to-top__btn');
		const navLanguages = document.querySelector('.nav-languages');
		const header = document.querySelector('.universal-nav');
		if (!isMobile()) {
			if (
				document.body.scrollTop > 20 ||
				document.documentElement.scrollTop > 20
			) {
				backToTopBtn.style.display = 'flex';
				if (navLanguages) {
					navLanguages.style.display = 'none';
				}
			} else {
				backToTopBtn.style.display = 'none';
				if (navLanguages) {
					navLanguages.style.display = 'block';
				}
			}
		}

		if (this.isFoldable && this.isScrollingDown()) {
			header.classList.add('is-hidden');
			this.body.classList.add('nav-hidden');
		} else {
			header.classList.remove('is-hidden');
			this.body.classList.remove('nav-hidden');
		}
	}

	isScrollingDown() {
		const currentScrolledPosition = window.scrollY || window.pageYOffset;
		let scrollingDown;

		if (currentScrolledPosition > this.previousScrollPositon) {
			scrollingDown = true;
		} else {
			scrollingDown = false;
		}
		this.previousScrollPositon = Math.abs(currentScrolledPosition);

		return scrollingDown;
	}

	toTheTop() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
}

export default ScrollTop;
