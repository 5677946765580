import { isMobile, lockScrolling, unlockScrolling } from '../helpers/helpers';

class Languages {
	constructor() {
		this.navLangDropdownTicket = document.querySelector(
			'.nav-languages--ticket'
		);
		this.navLangDropdown = document.querySelector('.nav-languages');
		this.navLangBtn = document.querySelector('.nav-languages__btn');
		this.navLangContent = document.querySelector('.nav-languages__content');

		this.handleLanguages();
	}

	handleLanguages() {
		if (this.navLangDropdownTicket) {
			const navLangBtnTicket = this.navLangDropdownTicket.querySelector(
				'.nav-languages__btn'
			);
			const navLangContentTicket = this.navLangDropdownTicket.querySelector(
				'.nav-languages__content'
			);
			if (navLangBtnTicket && navLangContentTicket) {
				navLangBtnTicket.addEventListener('click', () => {
					navLangBtnTicket.setAttribute('aria-expanded', true);
					navLangContentTicket.setAttribute('aria-hidden', false);
					navLangContentTicket.classList.add('nav-languages__content--open');
					lockScrolling();
				});
				const navLangCloseTicket = this.navLangDropdownTicket.querySelector(
					'.nav-languages__x-icon'
				);
				navLangCloseTicket.addEventListener('click', () => {
					navLangBtnTicket.setAttribute('aria-expanded', false);
					navLangContentTicket.setAttribute('aria-hidden', true);
					navLangContentTicket.classList.remove('nav-languages__content--open');
					unlockScrolling();
				});
			}
		}

		if (this.navLangDropdown && this.navLangContent) {
			this.navLangDropdown.addEventListener('mouseenter', () => {
				if (
					!isMobile() &&
					this.navLangContent.classList.contains('navClosing') === false
				) {
					this.openLanguage();
				} else {
					this.navLangContent.classList.add('navEnterWhileClosing');
				}
			});

			this.navLangDropdown.addEventListener('mouseleave', () => {
				if (
					!isMobile() &&
					this.navLangDropdown.classList.contains('navClosing') === false &&
					this.navLangDropdown.classList.contains('navEnterWhileClosing') ===
						false &&
					this.navLangContent.classList.contains(
						'nav-languages__content--open'
					) === true
				) {
					this.navLangContent.classList.add('navClosing');
					this.closeLanguage();
				}
			});

			this.navLangDropdown.addEventListener('click', () => {
				if (isMobile()) {
					this.toggleIcon();
				}
				if (
					this.navLangContent.classList.contains('nav-languages__content--open')
				) {
					this.closeLanguage();
				} else {
					this.openLanguage();
				}
			});

			this.navLangBtn.addEventListener('keydown', (e) => {
				if (e.shiftKey && e.keyCode === 9) {
					if (isMobile()) {
						this.toggleIcon();
					}
					if (
						this.navLangContent.classList.contains(
							'nav-languages__content--open'
						)
					) {
						this.closeLanguage();
					}
				}
			});

			const lastLangOption = this.navLangContent.querySelector(
				'li:nth-last-child(2) > a'
			);
			if (lastLangOption) {
				lastLangOption.addEventListener('keydown', (e) => {
					if (!e.shiftKey && e.keyCode === 9) {
						this.closeLanguage();
					}
				});
			}

			const firstLangOption = this.navLangContent.querySelector(
				'li:nth-child(2) > a'
			);
			if (firstLangOption) {
				firstLangOption.addEventListener('keydown', (e) => {
					if (e.shiftKey && e.keyCode === 9) {
						this.closeLanguage();
					}
				});
			}
		}
	}

	toggleIcon() {
		const navLangIcon = this.navLangBtn.querySelector('.icon');
		navLangIcon.classList.toggle('nav-icon-plus');
		navLangIcon.classList.toggle('nav-icon-plus--small');
		navLangIcon.classList.toggle('nav-icon-minus');
		navLangIcon.classList.toggle('nav-icon-minus--small');
	}

	openLanguage() {
		this.navLangContent.classList.remove('navEnterWhileClosing');

		if (this.navLangContent.classList.contains('nav-animate__languages--up')) {
			this.navLangContent.classList.remove('nav-animate__languages--up');
		}

		this.navLangBtn.setAttribute('aria-expanded', true);
		this.navLangContent.setAttribute('aria-hidden', false);

		this.navLangBtn.classList.add('nav-languages__btn--open');
		this.navLangContent.classList.add('nav-languages__content--open');

		if (!isMobile()) {
			this.navLangContent.classList.add('nav-animate__languages--down');
		}
	}

	closeLanguage() {
		this.navLangBtn.setAttribute('aria-expanded', false);
		this.navLangContent.setAttribute('aria-hidden', true);

		this.navLangBtn.classList.remove('nav-languages__btn--open');

		if (!isMobile()) {
			this.navLangContent.classList.remove('nav-animate__languages--down');
			this.navLangContent.classList.add('nav-animate__languages--up');
			this.navLangContent.addEventListener(
				'animationend',
				this.transitionEndCallback
			);
		} else {
			this.navLangContent.classList.remove('nav-languages__content--open');
		}
	}

	transitionEndCallback(event) {
		if (event.animationName === 'nav-anim__drop-up') {
			this.classList.remove('nav-languages__content--open');
			this.classList.remove('nav-animate__languages--up');
			this.classList.remove('navClosing');

			this.removeEventListener('animationend', this.transitionEndCallback);
		}
	}
}

export default Languages;
