import {
	isMobile,
	debounce,
	lockScrolling,
	unlockScrolling,
} from '../helpers/helpers';

class Mega {
	constructor() {
		this.megaMenuBtn = document.querySelectorAll('.nav-mega-menu__btn');
		this.megaMenuCloseBtn = document.querySelectorAll('.nav-mega-menu__x-icon');

		this.navCta = document.querySelector('.nav-cta:not(.nav-mobile__cta)');
		this.navMobileCta = document.querySelector('.nav-mobile__cta');

		if (this.navCta && this.navMobileCta) {
			this.megaMenuBtnDesktop = this.navCta.querySelector(
				'.nav-mega-menu__btn--desktop'
			);
			this.megaMenuContentDesktop = this.navCta.querySelector(
				'.nav-mega-menu__content'
			);
			this.megaMenuBtnMobile = this.navMobileCta.querySelector(
				'.nav-mega-menu__btn--mobile'
			);
			this.megaMenuContentMobile = this.navMobileCta.querySelector(
				'.nav-mega-menu__content'
			);
			this.megaMenuCloseMobile = this.navMobileCta.querySelector(
				'.nav-mega-menu__x-icon'
			);
		}

		this.menuIcon = document.querySelector('.nav-mobile-header__menu-icon');
		this.siteHeader = document.querySelector('.nav-container');

		this.handleDesktopMega();
		this.handleMobileMega();
		this.handleMobileResize();
	}

	handleDesktopMega() {
		if (this.navCta) {
			if (this.megaMenuBtnDesktop && this.megaMenuContentDesktop) {
				// EventListener to handle the click on the MegaMenu btn
				this.megaMenuBtnDesktop.addEventListener('click', () => {
					if (
						this.megaMenuBtnDesktop.classList.contains(
							'nav-mega-menu__btn--open'
						)
					) {
						this.closeDesktopMegaMenu();
					} else {
						this.openDesktopMegaMenu();
					}
				});

				// EventListener to close the MegaMenu if it's open and you Shift + Tab out of it
				this.megaMenuBtnDesktop.addEventListener('keydown', (e) => {
					if (e.shiftKey && e.keyCode === 9) {
						if (
							this.megaMenuBtnDesktop.classList.contains(
								'nav-mega-menu__btn--open'
							)
						) {
							this.closeDesktopMegaMenu();
						}
					}
				});

				// EventListener to close the MegaMenu when you Tab on the last item of the list
				const arrayLinkMegaMenuShow = this.megaMenuContentDesktop.querySelectorAll(
					'a'
				);
				const lastMegaMenuShow =
					arrayLinkMegaMenuShow[arrayLinkMegaMenuShow.length - 1];
				if (lastMegaMenuShow) {
					lastMegaMenuShow.addEventListener('keydown', (e) => {
						if (!e.shiftKey && e.keyCode === 9) {
							this.closeDesktopMegaMenu();
						}
					});
				}

				// EventListener to close the the MegaMenu when you Shift + Tab on the first item of the list
				// let firstMegaMenuShow = this.megaMenuContentDesktop.querySelector("li:nth-child(2) > a");
				const firstMegaMenuShow = arrayLinkMegaMenuShow[0];
				if (firstMegaMenuShow) {
					firstMegaMenuShow.addEventListener('keydown', (e) => {
						if (e.shiftKey && e.keyCode === 9) {
							this.closeDesktopMegaMenu();
						}
					});
				}

				// EventListener to close the Mega menu when it's open and you click anywhere else on the screen
				document.addEventListener('mousedown', (e) => {
					if (
						this.megaMenuBtnDesktop.classList.contains(
							'nav-mega-menu__btn--open'
						)
					) {
						const isInsideContent = this.megaMenuContentDesktop.contains(
							e.target
						);
						const isInsideBtn = this.megaMenuBtnDesktop.contains(e.target);
						if (!isInsideContent && !isInsideBtn) {
							this.closeDesktopMegaMenu();
						}
					}
				});
			}
		}
	}

	openDesktopMegaMenu() {
		if (
			this.megaMenuContentDesktop.classList.contains('nav-animate__mega--up')
		) {
			this.megaMenuContentDesktop.classList.remove('nav-animate__mega--up');
		}
		this.megaMenuBtnDesktop.setAttribute('aria-expanded', true);
		this.megaMenuContentDesktop.setAttribute('aria-hidden', false);
		this.megaMenuBtnDesktop.classList.add('nav-mega-menu__btn--open');
		this.megaMenuContentDesktop.hidden = false;
		this.megaMenuContentDesktop.classList.add('nav-animate__mega--down');
	}

	closeDesktopMegaMenu() {
		this.megaMenuBtnDesktop.setAttribute('aria-expanded', false);
		this.megaMenuContentDesktop.setAttribute('aria-hidden', true);
		this.megaMenuBtnDesktop.classList.remove('nav-mega-menu__btn--open');
		this.megaMenuContentDesktop.classList.remove('nav-animate__mega--down');
		this.megaMenuContentDesktop.classList.add('nav-animate__mega--up');
		this.megaMenuContentDesktop.addEventListener('animationend', (e) => {
			if (e.animationName === 'nav-anim__drop-up--mega') {
				this.megaMenuContentDesktop.hidden = true;
				this.megaMenuContentDesktop.classList.remove('nav-animate__mega--up');
			}
		});
	}

	handleMobileMega() {
		if (this.navCta) {
			if (this.megaMenuBtnMobile && this.megaMenuContentMobile) {
				this.megaMenuBtnMobile.addEventListener('click', () => {
					if (
						this.megaMenuBtnMobile.classList.contains(
							'nav-mega-menu__btn--open'
						)
					) {
						this.closeMobileMegaMenu();
					} else {
						this.openMobileMegaMenu();
					}
				});

				const mobileLinkMegaMenuShow = this.megaMenuContentMobile.querySelectorAll(
					'a'
				);
				const lastMobileMegaMenuShow =
					mobileLinkMegaMenuShow[mobileLinkMegaMenuShow.length - 1];
				if (lastMobileMegaMenuShow) {
					lastMobileMegaMenuShow.addEventListener('keydown', (e) => {
						if (e.keyCode === 9) {
							e.preventDefault();
							this.closeMobileMegaMenu();
						}
					});
				}

				if (this.megaMenuCloseMobile) {
					this.megaMenuCloseMobile.addEventListener('click', () => {
						this.closeMobileMegaMenu();
					});
				}

				if (this.menuIcon) {
					this.menuIcon.addEventListener('click', () => {
						if (
							this.megaMenuBtnMobile.classList.contains(
								'nav-mega-menu__btn--open'
							)
						) {
							this.closeMobileMegaMenu();
						}
					});
				}
			}
		}
	}

	openMobileMegaMenu() {
		this.megaMenuBtnMobile.setAttribute('aria-expanded', true);
		this.megaMenuContentMobile.setAttribute('aria-hidden', false);
		this.megaMenuBtnMobile.classList.add('nav-mega-menu__btn--open');
		this.megaMenuContentMobile.hidden = false;
		this.megaMenuContentMobile.classList.add('nav-animate__open--right');
		lockScrolling();
	}

	closeMobileMegaMenu() {
		this.megaMenuBtnMobile.setAttribute('aria-expanded', false);
		this.megaMenuContentMobile.setAttribute('aria-hidden', true);
		this.megaMenuBtnMobile.classList.remove('nav-mega-menu__btn--open');
		this.megaMenuContentMobile.classList.remove('nav-animate__open--right');
		this.megaMenuContentMobile.classList.add('nav-animate__close--right');
		this.megaMenuContentMobile.addEventListener('animationend', (e) => {
			if (e.animationName === 'nav-anim__close-right') {
				this.megaMenuContentMobile.hidden = true;
				this.megaMenuContentMobile.classList.remove(
					'nav-animate__close--right'
				);
			}
		});
		unlockScrolling();
	}

	eventResize() {
		if (this.navCta) {
			if (this.megaMenuContentMobile) {
				if (isMobile()) {
					this.megaMenuContentMobile.setAttribute('aria-hidden', true);
				} else {
					this.megaMenuContentMobile.hidden = true;
					this.megaMenuContentMobile.setAttribute('aria-hidden', false);
					unlockScrolling();
					this.megaMenuBtnMobile.classList.remove('nav-mega-menu__btn--open');
				}
			}
		}
	}

	handleMobileResize() {
		window.addEventListener(
			'resize',
			debounce(() => {
				this.eventResize();
			}, 100)
		);
	}
}

export default Mega;
