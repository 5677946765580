import { isMobile } from '../helpers/helpers';

class Club {
	constructor() {
		this.navClubDropdown = document.querySelector('.nav-club');
		this.navClubButton = document.querySelector('.nav-club__btn');
		this.navClubContent = document.querySelector('.nav-club__content');

		this.handleClub();
	}

	handleClub() {
		if (this.navClubDropdown && this.navClubButton && this.navClubContent) {
			this.navClubDropdown.addEventListener('mouseenter', () => {
				if (
					!isMobile() &&
					this.navClubContent.classList.contains('navClosing') === false
				) {
					this.openClub();
				} else {
					this.navClubContent.classList.add('navEnterWhileClosing');
				}
			});

			this.navClubDropdown.addEventListener('mouseleave', () => {
				if (isMobile()) {
					this.navClubContent.classList.remove('nav-club__content--open');
				} else if (
					!isMobile() &&
					this.navClubContent.classList.contains('navClosing') === false &&
					this.navClubContent.classList.contains('navEnterWhileClosing') ===
						false &&
					this.navClubContent.classList.contains('nav-club__content--open') ===
						true
				) {
					this.navClubContent.classList.add('navClosing');
					this.closeClub();
				}
			});

			this.navClubButton.addEventListener('click', () => {
				if (!isMobile()) {
					if (
						this.navClubContent.classList.contains('nav-club__content--open')
					) {
						this.closeClub();
					} else {
						this.openClub();
					}
				}
			});

			this.navClubButton.addEventListener('keydown', (e) => {
				if (e.shiftKey && e.keyCode === 9) {
					if (!isMobile()) {
						if (
							this.navClubContent.classList.contains('nav-club__content--open')
						) {
							this.closeClub();
						}
					}
				}
			});

			this.navClubContent
				.querySelector('li:last-child > a')
				.addEventListener('keydown', (e) => {
					if (!e.shiftKey && e.keyCode === 9) {
						if (!isMobile()) {
							this.closeClub();
						}
					}
				});

			this.navClubContent
				.querySelector('li:first-child > a')
				.addEventListener('keydown', (e) => {
					if (e.shiftKey && e.keyCode === 9) {
						if (!isMobile()) {
							this.closeClub();
						}
					}
				});
		}
	}

	openClub() {
		this.navClubContent.classList.remove('navEnterWhileClosing');

		if (this.navClubContent.classList.contains('nav-animate__club--up')) {
			this.navClubContent.classList.remove('nav-animate__club--up');
		}

		this.navClubButton.setAttribute('aria-expanded', true);
		this.navClubContent.setAttribute('aria-hidden', false);
		this.navClubButton.classList.add('nav-club__btn--open');
		this.navClubContent.classList.add('nav-club__content--open');

		if (!isMobile()) {
			this.navClubContent.classList.add('nav-animate__club--down');
		}
	}

	closeClub() {
		this.navClubButton.setAttribute('aria-expanded', false);
		this.navClubContent.setAttribute('aria-hidden', true);
		this.navClubButton.classList.remove('nav-club__btn--open');

		if (!isMobile()) {
			this.navClubContent.classList.remove('nav-animate__club--down');
			this.navClubContent.classList.add('nav-animate__club--up');
			this.navClubContent.addEventListener(
				'animationend',
				this.transitionEndCallback
			);
		} else {
			this.navClubContent.classList.remove('nav-club__content--open');
		}
	}

	transitionEndCallback(event) {
		if (event.animationName === 'nav-anim__drop-up') {
			this.classList.remove('nav-club__content--open');
			this.classList.remove('nav-animate__club--up');
			this.classList.remove('navClosing');

			this.removeEventListener('animationend', this.transitionEndCallback);
		}
	}
}

export default Club;
