export const isMobile = () => window.matchMedia('(max-width: 1279px)').matches;

export const lockScrolling = () => {
	const html = document.querySelector('html');
	// const body = document.querySelector('body');
	html.style.setProperty('overflow', 'hidden');
	// body.style.setProperty('overflow', 'hidden');
};

export const unlockScrolling = () => {
	const html = document.querySelector('html');
	// const body = document.querySelector('body');
	html.style.removeProperty('overflow');
	// body.style.removeProperty('overflow');
};

export function debounce(func, wait) {
	let timeout;
	return function () {
		const functionCall = () => func.apply(this, arguments);
		clearTimeout(timeout);
		timeout = setTimeout(functionCall, wait);
	};
}

export function throttle(func, wait) {
	let waiting = false;
	return function () {
		if (waiting) {
			return;
		}

		waiting = true;
		setTimeout(() => {
			func.apply(this, arguments);
			waiting = false;
		}, wait);
	};
}
