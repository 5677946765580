import { isMobile, debounce } from '../helpers/helpers';

class Microsite {
	constructor() {
		this.micrositeBtn = document.querySelector('.nav-microsite__main-btn');
		this.micrositeDropdowns = document.querySelectorAll(
			'.nav-microsite__dropdown'
		);
		this.micrositeSecondBtn = document.querySelectorAll(
			'button.nav-microsite__btn'
		);
		this.secondaryBtn = document.querySelectorAll(
			'.nav-microsite__secondary-btn'
		);

		this.isIE = document.querySelector('html').classList.contains('IE');

		this.micrositeMouseHover();
		this.handleMicrosite();
		this.handleResize();
	}

	openFirstPanel(item) {
		item.classList.remove('navEnterWhileClosing');
		const firstSubmenu = item.querySelector('.nav-microsite__first-level');
		const firstBtn = item.querySelector('.nav-microsite__btn');

		if (firstSubmenu.classList.contains('nav-animate__microsite--up')) {
			firstSubmenu.classList.remove('nav-animate__microsite--up');
		}

		firstBtn.setAttribute('aria-expanded', true);
		firstSubmenu.setAttribute('aria-hidden', false);
		firstBtn.classList.add('nav-microsite__btn--open');
		firstSubmenu.classList.add('nav-microsite__first-level--open');
		item.querySelector('.nav-arrow').classList.remove('nav-arrow--down--grey');

		if (!isMobile()) {
			firstSubmenu.classList.add('nav-animate__microsite--down');
		}
	}

	closeFirstPanel(item) {
		const firstSubmenu = item.querySelector('.nav-microsite__first-level');
		const firstBtn = item.querySelector('.nav-microsite__btn');
		firstBtn.classList.remove('nav-microsite__btn--open');
		firstBtn.setAttribute('aria-expanded', false);
		item.querySelector('.nav-arrow').classList.add('nav-arrow--down--grey');

		firstSubmenu.setAttribute('aria-hidden', true);
		if (!isMobile()) {
			firstSubmenu.classList.remove('nav-animate__microsite--down');
			firstSubmenu.classList.add('nav-animate__microsite--up');
			firstSubmenu.addEventListener('animationend', this.transitionEndCallback);
		} else {
			firstSubmenu.classList.remove('nav-microsite__first-level--open');
		}
	}

	resetPanel() {
		const listOfSecondaryBtn = document.querySelectorAll(
			'.nav-microsite__second-level'
		);
		const arrayOfSecondaryBtn = [...listOfSecondaryBtn];
		arrayOfSecondaryBtn.forEach(function (item) {
			item.hidden = false;
		});
		const listOfSecondaryPanels = document.querySelectorAll(
			'.nav-microsite__secondary'
		);
		const arrayOfSecondaryPanels = [...listOfSecondaryPanels];
		arrayOfSecondaryPanels.forEach((panel) => {
			panel.classList.remove('nav-microsite__secondary--open');
		});
		const secondaryBtn = document.querySelectorAll(
			'.nav-microsite__secondary-btn'
		);
		if (secondaryBtn) {
			const arraySecondaryBtn = [...secondaryBtn];
			arraySecondaryBtn.forEach((item) => {
				if (item.classList.contains('nav-microsite__secondary-btn--open')) {
					const arrows = item.querySelectorAll('.nav-arrow');
					const arrayArrows = [...arrows];
					arrayArrows.forEach((arrow) =>
						arrow.classList.toggle('nav-arrow--hidden')
					);
				}
				item.classList.remove('nav-microsite__secondary-btn--open');
				item.blur();
			});
		}
	}

	micrositeMouseHover() {
		if (this.micrositeDropdowns) {
			const arrayMicrositeDropdowns = [...this.micrositeDropdowns];
			arrayMicrositeDropdowns.forEach((dropdown) => {
				dropdown.addEventListener('mouseenter', () => {
					if (window.matchMedia('(max-width: 1279px)').matches === false) {
						if (dropdown.classList.contains('navClosing') === false) {
							this.openFirstPanel(dropdown);
						} else {
							dropdown.classList.add('navEnterWhileClosing');
						}
					}
				});
				dropdown.addEventListener('mouseleave', () => {
					if (window.matchMedia('(max-width: 1279px)').matches === false) {
						if (
							dropdown.classList.contains('navClosing') === false &&
							dropdown.classList.contains('navEnterWhileClosing') === false &&
							dropdown
								.querySelector('.nav-microsite__btn')
								.classList.contains('nav-microsite__btn--open') === true
						) {
							dropdown.classList.add('navClosing');
							this.closeFirstPanel(dropdown);
						}
					}
				});
				dropdown.addEventListener('mouseleave', this.resetPanel);
			});
		}
	}

	handleResize() {
		window.addEventListener(
			'resize',
			debounce(() => {
				// Remove/Add the events listener
				this.micrositeMouseHover();
				// Close all the second panels
				if (this.micrositeSecondBtn !== null) {
					const arrayMicrositeSecondBtn = [...this.micrositeSecondBtn];
					arrayMicrositeSecondBtn.forEach((elem) => {
						this.closeSecondLvlMenu(elem);
					});
				}
				// Reset the styles in the seconds panels
				this.resetPanel();
			}, 10)
		);
	}

	openMicroMenu() {
		const PrimaryMenu = document.querySelector('.nav-primary__container');
		const menu = document.querySelector('.nav-microsite-menu');
		const submenu = document.querySelector('.nav-microsite__container');
		const arrow = this.micrositeBtn.querySelector('.nav-arrow');

		PrimaryMenu.classList.add('nav-primary__container--open');
		menu.classList.add('nav-microsite-menu--open');
		submenu.classList.add('nav-microsite__container--open');

		// Set a11y
		this.micrositeBtn.setAttribute('aria-expanded', true);
		submenu.setAttribute('aria-hidden', false);

		// change arrows
		arrow.classList.remove('nav-arrow--left');
		arrow.classList.add('nav-arrow--right');
		arrow.classList.remove('nav-arrow--alternate');

		// Set focus
		document.querySelector('.nav-logo a').focus();
	}

	closeMicroMenu() {
		const PrimaryMenu = document.querySelector('.nav-primary__container');
		const menu = document.querySelector('.nav-microsite-menu');
		const submenu = document.querySelector('.nav-microsite__container');
		const arrow = this.micrositeBtn.querySelector('.nav-arrow');

		PrimaryMenu.classList.remove('nav-primary__container--open');
		menu.classList.remove('nav-microsite-menu--open');
		submenu.classList.remove('nav-microsite__container--open');

		// Set a11y
		this.micrositeBtn.setAttribute('aria-expanded', false);
		submenu.setAttribute('aria-hidden', true);

		// Change arrows
		arrow.classList.add('nav-arrow--left');
		arrow.classList.remove('nav-arrow--right');
		arrow.classList.add('nav-arrow--alternate');

		// Set Focus
		this.micrositeBtn.focus();
	}

	handleMicrosite() {
		// Opening the microsite menu
		if (this.micrositeBtn !== null) {
			this.micrositeBtn.addEventListener('click', () => {
				const PrimaryMenu = document.querySelector('.nav-primary__container');
				if (PrimaryMenu.classList.contains('nav-primary__container--open')) {
					this.closeMicroMenu();
				} else {
					this.openMicroMenu();
				}
			});
		}

		// Opening Second Level Panel
		if (this.micrositeSecondBtn !== null) {
			const arrayMicrositeSecondBtn = [...this.micrositeSecondBtn];
			arrayMicrositeSecondBtn.forEach((elem) => {
				// Handle the click on to open or close Second Level Panel
				elem.addEventListener('click', () => {
					if (elem.classList.contains('nav-microsite__btn--open')) {
						this.closeSecondLvlMenu(elem);
					} else {
						this.openSecondLvlMenu(elem);
					}
				});

				// Handle the auto-close on tabing on first and last elements of the second panel
				const secondMenuItems = elem.nextElementSibling.querySelectorAll(
					'.nav-microsite__second-level'
				);
				const firstSecondMenuItem = secondMenuItems[0];
				const lastSecondMenuItem = secondMenuItems[secondMenuItems.length - 1];

				if (firstSecondMenuItem !== null) {
					firstSecondMenuItem.addEventListener('keydown', (e) => {
						if (
							firstSecondMenuItem.querySelector(
								'.nav-microsite__secondary-btn--open'
							) !== null
						) {
						} else if (e.shiftKey && e.keyCode === 9) {
							this.closeSecondLvlMenu(elem);
						}
					});
				}

				if (lastSecondMenuItem !== null) {
					lastSecondMenuItem.addEventListener('keydown', (e) => {
						if (
							lastSecondMenuItem.querySelector(
								'.nav-microsite__secondary-btn--open'
							) !== null
						) {
						} else if (!e.shiftKey && e.keyCode === 9) {
							this.closeSecondLvlMenu(elem);
						}
					});
				}
			});
		}

		// Opening Third Level Panel
		if (this.secondaryBtn !== null) {
			const arraySecondaryBtn = [...this.secondaryBtn];
			arraySecondaryBtn.forEach((elem) => {
				elem.addEventListener('click', () => {
					if (elem.classList.contains('nav-microsite__secondary-btn--open')) {
						this.closeThirdLvlMenu(elem);
					} else {
						this.openThirdLvlMenu(elem);
					}
				});
				elem.addEventListener('keydown', (e) => {
					if (e.shiftKey && e.keyCode === 9) {
						if (elem.classList.contains('nav-microsite__secondary-btn--open')) {
							this.closeThirdLvlMenu(elem);
						}
					}
				});

				let openThirdLvlTimeout;
				let closeThirdLvlMenu;
				elem.parentElement.addEventListener('mouseenter', () => {
					if (window.matchMedia('(max-width: 1279px)').matches === false) {
						clearTimeout(closeThirdLvlMenu);
						openThirdLvlTimeout = setTimeout(
							() => this.openThirdLvlMenu(elem),
							200
						);
					}
				});

				elem.parentElement.addEventListener('mouseleave', () => {
					if (window.matchMedia('(max-width: 1279px)').matches === false) {
						clearTimeout(openThirdLvlTimeout);
						closeThirdLvlMenu = setTimeout(
							() => this.closeThirdLvlMenu(elem),
							200
						);
					}
				});

				const thirdMenuItems = elem.nextElementSibling.querySelectorAll('li');
				const firstThirdMenuItem = thirdMenuItems[0];
				const lastThirdMenuItem = thirdMenuItems[thirdMenuItems.length - 1];

				if (firstThirdMenuItem !== null) {
					firstThirdMenuItem.addEventListener('keydown', (e) => {
						if (e.shiftKey && e.keyCode === 9) {
							this.closeThirdLvlMenu(elem);
						}
					});
				}

				if (lastThirdMenuItem !== null) {
					lastThirdMenuItem.addEventListener('keydown', (e) => {
						if (!e.shiftKey && e.keyCode === 9) {
							this.closeThirdLvlMenu(elem);
						}
					});
				}
			});
		}
	}

	openSecondLvlMenu(elem) {
		const firstSubmenu = elem.nextElementSibling;
		firstSubmenu.classList.remove('navEnterWhileClosing');
		if (firstSubmenu.classList.contains('nav-animate__microsite--up')) {
			firstSubmenu.classList.remove('nav-animate__microsite--up');
		}
		elem.classList.add('nav-microsite__btn--open');
		elem.setAttribute('aria-expanded', true);

		firstSubmenu.setAttribute('aria-hidden', false);
		firstSubmenu.classList.add('nav-microsite__first-level--open');
		if (window.matchMedia('(max-width: 1279px)').matches) {
			const iconPlus = elem.querySelector('.nav-icon-plus');
			iconPlus.classList.remove('nav-icon-plus');
			iconPlus.classList.add('nav-icon-minus');
		}
		if (!isMobile()) {
			firstSubmenu.classList.add('nav-animate__microsite--down');
		}
	}

	closeSecondLvlMenu(elem) {
		const firstSubmenu = elem.nextElementSibling;

		elem.setAttribute('aria-expanded', false);
		firstSubmenu.setAttribute('aria-hidden', true);

		elem.classList.remove('nav-microsite__btn--open');

		if (window.matchMedia('(max-width: 1279px)').matches) {
			const iconPlus = elem.querySelector('.nav-icon-minus');
			if (iconPlus) {
				iconPlus.classList.add('nav-icon-plus');
				iconPlus.classList.remove('nav-icon-minus');
			}
		}
		if (!window.matchMedia('(max-width: 1279px)').matches) {
			firstSubmenu.classList.remove('nav-animate__microsite--down');
			firstSubmenu.classList.add('nav-animate__microsite--up');
			firstSubmenu.addEventListener('animationend', this.transitionEndCallback);
		} else {
			firstSubmenu.classList.remove('nav-microsite__first-level--open');
		}
	}

	transitionEndCallback(event) {
		if (event.animationName === 'nav-anim__drop-up--microsite') {
			this.classList.remove('nav-microsite__first-level--open');
			this.classList.remove('nav-animate__microsite--up');
			this.parentElement.classList.remove('navClosing');

			this.removeEventListener('animationend', this.transitionEndCallback);
		}
	}

	openThirdLvlMenu(elem) {
		elem.setAttribute('aria-expanded', true);
		const itemNumber = elem.parentNode.dataset.item;
		elem.classList.add('nav-microsite__secondary-btn--open');
		const thirdLvlMenu = elem.nextElementSibling;
		const nbOfThridLvlItems = thirdLvlMenu.childElementCount;
		let nbOfSecondLvlItems = elem.parentElement.parentElement.childElementCount;

		if (nbOfSecondLvlItems < 6 && nbOfThridLvlItems > nbOfSecondLvlItems) {
			nbOfSecondLvlItems = 6;
		}

		const thirdLvlWidth =
			Math.ceil(nbOfThridLvlItems / nbOfSecondLvlItems) * 275;
		let nbOfCol = 0;
		if (thirdLvlWidth <= 275) {
			nbOfCol = 1;
		} else if (thirdLvlWidth > 275 && thirdLvlWidth <= 550) {
			nbOfCol = 2;
		} else {
			nbOfCol = 3;
		}

		if (
			thirdLvlMenu.classList.contains(
				`nav-animate__microsite--close--${nbOfCol}col`
			)
		) {
			thirdLvlMenu.classList.remove(
				`nav-animate__microsite--close--${nbOfCol}col`
			);
		}

		thirdLvlMenu.classList.add('nav-microsite__secondary--open');
		thirdLvlMenu.setAttribute('aria-hidden', false);

		if (window.matchMedia('(max-width: 1279px)').matches === false) {
			thirdLvlMenu.classList.add(`nav-animate__microsite--open--${nbOfCol}col`);
			thirdLvlMenu.style.width = `${thirdLvlWidth}px`;
			const secondLvlHeight = elem.parentElement.parentElement.clientHeight;
			thirdLvlMenu.style.minHeight = `${secondLvlHeight}px`;
			if (this.isIE) {
				if (secondLvlHeight < 283) {
					thirdLvlMenu.style.height = '283px';
				} else {
					thirdLvlMenu.style.height = `${secondLvlHeight}px`;
				}
			}
		} else {
			thirdLvlMenu.removeAttribute('style');
		}
		const listOfSecondary = elem.parentElement.parentElement.querySelectorAll(
			'.nav-microsite__second-level'
		);
		if (window.matchMedia('(max-width: 1279px)').matches) {
			const arrayOfSecondary = [...listOfSecondary];
			arrayOfSecondary.forEach((item) => {
				if (item.dataset.item !== itemNumber) {
					item.hidden = true;
				}
			});
			const arrows = elem.querySelectorAll('.nav-arrow');
			const arrayArrows = [...arrows];
			arrayArrows.forEach((arrow) =>
				arrow.classList.toggle('nav-arrow--hidden')
			);
		}
	}

	closeThirdLvlMenu(elem) {
		elem.setAttribute('aria-expanded', false);
		const itemNumber = elem.parentNode.dataset.item;
		elem.classList.remove('nav-microsite__secondary-btn--open');
		const thirdLvlMenu = elem.nextElementSibling;
		const nbOfThridLvlItems = thirdLvlMenu.childElementCount;
		let nbOfSecondLvlItems = elem.parentElement.parentElement.childElementCount;

		if (nbOfSecondLvlItems < 6 && nbOfThridLvlItems > nbOfSecondLvlItems) {
			nbOfSecondLvlItems = 6;
		}

		const thirdLvlWidth =
			Math.ceil(nbOfThridLvlItems / nbOfSecondLvlItems) * 275;
		let nbOfCol = 0;
		if (thirdLvlWidth <= 275) {
			nbOfCol = 1;
		} else if (thirdLvlWidth > 275 && thirdLvlWidth <= 550) {
			nbOfCol = 2;
		} else {
			nbOfCol = 3;
		}

		thirdLvlMenu.classList.remove(
			`nav-animate__microsite--close--${nbOfCol}col`
		);

		thirdLvlMenu.setAttribute('aria-hidden', true);
		const listOfSecondary = elem.parentElement.parentElement.querySelectorAll(
			'.nav-microsite__second-level'
		);
		if (isMobile()) {
			const arrayOfSecondary = [...listOfSecondary];
			arrayOfSecondary.forEach((item) => {
				if (item.dataset.item !== itemNumber) {
					item.hidden = false;
				}
			});
			const arrows = elem.querySelectorAll('.nav-arrow');
			const arrayArrows = [...arrows];
			arrayArrows.forEach((arrow) =>
				arrow.classList.toggle('nav-arrow--hidden')
			);
		}
		if (!isMobile()) {
			thirdLvlMenu.classList.remove(
				`nav-animate__microsite--open--${nbOfCol}col`
			);
			thirdLvlMenu.classList.add(
				`nav-animate__microsite--close--${nbOfCol}col`
			);
			thirdLvlMenu.addEventListener('animationend', (e) => {
				if (e.animationName === `close-third-menu-left-${nbOfCol}col`) {
					thirdLvlMenu.classList.remove(
						`nav-animate__microsite--close--${nbOfCol}col`
					);
					thirdLvlMenu.classList.remove('nav-microsite__secondary--open');
				}
			});
		} else {
			thirdLvlMenu.classList.remove('nav-microsite__secondary--open');
		}
	}
}

export default Microsite;
