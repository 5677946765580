import { isMobile } from '../helpers/helpers';

class Shows {
	constructor() {
		this.showButton = document.querySelector('.nav-show-container');
		this.showContent = document.querySelector('.nav-shows-grid');

		if (this.showButton) {
			this.showLink = this.showButton.querySelector('.nav-shows-grid__btn');
			this.showLinkBtn = this.showButton.querySelector('button');
		}

		this.handleShow();
	}

	handleShow() {
		if (this.showButton && this.showContent && this.showLink) {
			this.showButton.addEventListener('mouseenter', () => {
				if (
					!isMobile() &&
					this.showContent.classList.contains('navClosing') === false
				) {
					this.openShow();
				} else {
					this.showContent.classList.add('navEnterWhileClosing');
				}
			});
			this.showButton.addEventListener('mouseleave', () => {
				if (
					!isMobile() &&
					this.showContent.classList.contains('navClosing') === false &&
					this.showContent.classList.contains('navEnterWhileClosing') ===
						false &&
					this.showLink.classList.contains('nav-shows-grid__btn--open') === true
				) {
					this.showContent.classList.add('navClosing');
					this.closeShow();
				}
			});

			// Close the list when hitting the escape key
			this.showButton.addEventListener('keyup', (e) => {
				if (e.keyCode === 27) {
					this.closeShow();
				}
			});

			// Close the list when hitting tab on the last show
			this.showContent
				.querySelector('li:last-child > a')
				.addEventListener('keydown', (e) => {
					if (e.keyCode === 9 && !e.shiftKey) {
						this.closeShow();
					}
				});
			this.showContent
				.querySelector('li:first-child > a')
				.addEventListener('keydown', (e) => {
					if (e.keyCode === 9 && e.shiftKey) {
						this.closeShow();
					}
				});

			this.showLink.addEventListener('click', () => {
				if (this.showLink.classList.contains('nav-shows-grid__btn--open')) {
					this.closeShow();
				} else {
					this.openShow();
				}
			});

			this.showLink.addEventListener('keydown', (e) => {
				if (e.keyCode === 9 && e.shiftKey) {
					if (this.showLink.classList.contains('nav-shows-grid__btn--open')) {
						this.closeShow();
					}
				}
			});
		}
	}

	openShow() {
		this.showContent.classList.remove('navEnterWhileClosing');
		if (this.showContent.classList.contains('nav-animate__shows--up')) {
			this.showContent.classList.remove('nav-animate__shows--up');
		}
		this.showLinkBtn.setAttribute('aria-expanded', true);
		this.showContent.setAttribute('aria-hidden', false);
		this.showLinkBtn.classList.add('nav-shows-grid__btn--open');
		this.showContent.classList.add('nav-shows-grid--open');
		this.showContent.classList.add('nav-animate__shows--down');
	}

	closeShow() {
		this.showLinkBtn.setAttribute('aria-expanded', false);
		this.showContent.setAttribute('aria-hidden', true);
		this.showLinkBtn.classList.remove('nav-shows-grid__btn--open');
		this.showContent.classList.remove('nav-animate__shows--down');
		this.showContent.classList.add('nav-animate__shows--up');

		this.showContent.addEventListener(
			'animationend',
			this.transitionEndCallback
		);
	}

	transitionEndCallback(event) {
		if (event.animationName === 'nav-anim__drop-up--shows') {
			this.classList.remove('nav-shows-grid--open');
			this.classList.remove('nav-animate__shows--up');
			this.classList.remove('navClosing');

			this.removeEventListener('animationend', this.transitionEndCallback);
		}
	}
}

export default Shows;
