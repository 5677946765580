import Modal from './modules/Modal';
import MobileMenu from './modules/MobileMenu';
import Shows from './modules/Shows';
import Club from './modules/Club';
import Mega from './modules/Mega';
import Languages from './modules/Language';
import Microsite from './modules/Microsite';
import ScrollTop from './modules/ScrollTop';
import Footer from './modules/Footer';
import SkipNavigation from './modules/Skip';
import LegacySupport from './modules/LegacySupport';
import Search from './modules/Search';

const testIfUniNavIsHere = document.querySelector(
	'.nav-mobile-header__container'
);
const testIfUniFooterIsHere = document.querySelector('.footer-wrapper');

if (testIfUniNavIsHere) {
	const mobileMenu = new MobileMenu();
	const modal = new Modal();
	const shows = new Shows();
	const club = new Club();
	const mega = new Mega();
	const languages = new Languages();
	const microsite = new Microsite();
	const scrollTop = new ScrollTop();
	const skipNavigation = new SkipNavigation();
	const legacySupport = new LegacySupport();
	const search = new Search();
}

if (testIfUniFooterIsHere) {
	const footer = new Footer();
}
